* {
  font-family: "Open Sans";
  --ion-default-font:  "Open Sans";
  font-style: normal;
  font-weight: normal;
  --ion-font-family: var(--ion-default-font);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

@media (max-width: 279.98px) {
  html {
    font-size: 12px;
  }
}

@media  (min-width: 280px) and (max-width: 991.98px) {
  html {
    font-size: clamp(0.8125rem, 0.2472rem + 3.2303vw, 2.25rem);
  }
}

@media (min-width: 992px) {
  html {
    font-size: 16px;
  }
}

.f300 {
  font-weight: 300;
}

.f400 {
  font-weight: 400;
}

.f500 {
  font-weight: 500;
}

.f600 {
  font-weight: 600;
}

.f700 {
  font-weight: 700;
}

.fbold {
  font-weight: bold;
}

.fCfa {
  font-family: 'Comfortaa';
}

.fMontserrat {
  font-family: 'Montserrat';
}


