:root {
  --ion-color-cogniblue: #156087;
  --ion-color-cogniblue-rgb: 21,96,135;
  --ion-color-cogniblue-contrast: #ffffff;
  --ion-color-cogniblue-contrast-rgb: 255,255,255;
  --ion-color-cogniblue-shade: #125477;
  --ion-color-cogniblue-tint: #2c7093;

  --ion-color-cognibluesh: #5C7F9D;
  --ion-color-cognibluesh-rgb: 92,127,157;
  --ion-color-cognibluesh-contrast: #ffffff;
  --ion-color-cognibluesh-contrast-rgb: 255,255,255;
  --ion-color-cognibluesh-shade: #51708a;
  --ion-color-cognibluesh-tint: #6c8ca7;

  --ion-color-cognistone: #47758C;
  --ion-color-cognistone-rgb: 71,117,140;
  --ion-color-cognistone-contrast: #ffffff;
  --ion-color-cognistone-contrast-rgb: 255,255,255;
  --ion-color-cognistone-shade: #3e677b;
  --ion-color-cognistone-tint: #598398;

  --ion-color-cognisteel: #527593;
  --ion-color-cognisteel-rgb: 82,117,147;
  --ion-color-cognisteel-contrast: #ffffff;
  --ion-color-cognisteel-contrast-rgb: 255,255,255;
  --ion-color-cognisteel-shade: #486781;
  --ion-color-cognisteel-tint: #63839e;

  --ion-color-cognispruce: #2E4B59;
  --ion-color-cognispruce-rgb: 46,75,89;
  --ion-color-cognispruce-contrast: #ffffff;
  --ion-color-cognispruce-contrast-rgb: 255,255,255;
  --ion-color-cognispruce-shade: #28424e;
  --ion-color-cognispruce-tint: #435d6a;

  --ion-color-cognicyprus: #455A64;
  --ion-color-cognicyprus-rgb: 69,90,100;
  --ion-color-cognicyprus-contrast: #ffffff;
  --ion-color-cognicyprus-contrast-rgb: 255,255,255;
  --ion-color-cognicyprus-shade: #3d4f58;
  --ion-color-cognicyprus-tint: #586b74;

  --ion-color-cognislate: #6B9DB8;
  --ion-color-cognislate-rgb: 107,157,184;
  --ion-color-cognislate-contrast: #000000;
  --ion-color-cognislate-contrast-rgb: 0,0,0;
  --ion-color-cognislate-shade: #5e8aa2;
  --ion-color-cognislate-tint: #7aa7bf;

  --ion-color-cognisapphire: #92C1D3;
  --ion-color-cognisapphire-rgb: 146,193,211;
  --ion-color-cognisapphire-contrast: #000000;
  --ion-color-cognisapphire-contrast-rgb: 0,0,0;
  --ion-color-cognisapphire-shade: #80aaba;
  --ion-color-cognisapphire-tint: #9dc7d7;

  --ion-color-cognicharcoal: #383838;
  --ion-color-cognicharcoal-rgb: 56,56,56;
  --ion-color-cognicharcoal-contrast: #ffffff;
  --ion-color-cognicharcoal-contrast-rgb: 255,255,255;
  --ion-color-cognicharcoal-shade: #313131;
  --ion-color-cognicharcoal-tint: #4c4c4c;

  --ion-color-cognishadow: #888888;
  --ion-color-cognishadow-rgb: 136,136,136;
  --ion-color-cognishadow-contrast: #000000;
  --ion-color-cognishadow-contrast-rgb: 0,0,0;
  --ion-color-cognishadow-shade: #787878;
  --ion-color-cognishadow-tint: #949494;

  --ion-color-cognismoke: #5B5B5B;
  --ion-color-cognismoke-rgb: 91,91,91;
  --ion-color-cognismoke-contrast: #ffffff;
  --ion-color-cognismoke-contrast-rgb: 255,255,255;
  --ion-color-cognismoke-shade: #505050;
  --ion-color-cognismoke-tint: #6b6b6b;

  --ion-color-cognigrey: #A5A5A5;
  --ion-color-cognigrey-rgb: 165,165,165;
  --ion-color-cognigrey-contrast: #000000;
  --ion-color-cognigrey-contrast-rgb: 0,0,0;
  --ion-color-cognigrey-shade: #919191;
  --ion-color-cognigrey-tint: #aeaeae;

  --ion-color-cognisilver: #D7D7D7;
  --ion-color-cognisilver-rgb: 215,215,215;
  --ion-color-cognisilver-contrast: #000000;
  --ion-color-cognisilver-contrast-rgb: 0,0,0;
  --ion-color-cognisilver-shade: #bdbdbd;
  --ion-color-cognisilver-tint: #dbdbdb;

  --ion-color-cogniskeleton: #A6A6A6;
  --ion-color-cogniskeleton-rgb: 166,166,166;
  --ion-color-cogniskeleton-contrast: #000000;
  --ion-color-cogniskeleton-contrast-rgb: 0,0,0;
  --ion-color-cogniskeleton-shade: #929292;
  --ion-color-cogniskeleton-tint: #afafaf;

  --ion-color-cognisnow: #F3F0F0;
  --ion-color-cognisnow-rgb: 243,240,240;
  --ion-color-cognisnow-contrast: #000000;
  --ion-color-cognisnow-contrast-rgb: 0,0,0;
  --ion-color-cognisnow-shade: #d6d3d3;
  --ion-color-cognisnow-tint: #f4f2f2;

  --ion-color-cognifog: #EFEDED;
  --ion-color-cognifog-rgb: 239,237,237;
  --ion-color-cognifog-contrast: #000000;
  --ion-color-cognifog-contrast-rgb: 0,0,0;
  --ion-color-cognifog-shade: #d2d1d1;
  --ion-color-cognifog-tint: #f1efef;

  --ion-color-cognicloud: #EAEAEA;
  --ion-color-cognicloud-rgb: 234,234,234;
  --ion-color-cognicloud-contrast: #000000;
  --ion-color-cognicloud-contrast-rgb: 0,0,0;
  --ion-color-cognicloud-shade: #cecece;
  --ion-color-cognicloud-tint: #ececec;

  --ion-color-cognichalk: #FCFCFC;
  --ion-color-cognichalk-rgb: 252,252,252;
  --ion-color-cognichalk-contrast: #000000;
  --ion-color-cognichalk-contrast-rgb: 0,0,0;
  --ion-color-cognichalk-shade: #dedede;
  --ion-color-cognichalk-tint: #fcfcfc;

  --ion-color-cognimilk: #FEFEFE;
  --ion-color-cognimilk-rgb: 254,254,254;
  --ion-color-cognimilk-contrast: #000000;
  --ion-color-cognimilk-contrast-rgb: 0,0,0;
  --ion-color-cognimilk-shade: #e0e0e0;
  --ion-color-cognimilk-tint: #fefefe;

  --ion-color-cognired: #B0302F;
  --ion-color-cognired-rgb: 176,48,47;
  --ion-color-cognired-contrast: #ffffff;
  --ion-color-cognired-contrast-rgb: 255,255,255;
  --ion-color-cognired-shade: #9b2a29;
  --ion-color-cognired-tint: #b84544;

  --ion-color-cognioldrose: #F1ABA9;
  --ion-color-cognioldrose-rgb: 241,171,169;
  --ion-color-cognioldrose-contrast: #000000;
  --ion-color-cognioldrose-contrast-rgb: 0,0,0;
  --ion-color-cognioldrose-shade: #d49695;
  --ion-color-cognioldrose-tint: #f2b3b2;

  --ion-color-cogniblush: #FCD4D4;
  --ion-color-cogniblush-rgb: 252,212,212;
  --ion-color-cogniblush-contrast: #000000;
  --ion-color-cogniblush-contrast-rgb: 0,0,0;
  --ion-color-cogniblush-shade: #debbbb;
  --ion-color-cogniblush-tint: #fcd8d8;

  --ion-color-cognicrepe: #F2DCDE;
  --ion-color-cognicrepe-rgb: 242,220,222;
  --ion-color-cognicrepe-contrast: #000000;
  --ion-color-cognicrepe-contrast-rgb: 0,0,0;
  --ion-color-cognicrepe-shade: #d5c2c3;
  --ion-color-cognicrepe-tint: #f3e0e1;

  --ion-color-cognicoconutwhite: #F2F2F2;
  --ion-color-cognicoconutwhite-rgb: 242,242,242;
  --ion-color-cognicoconutwhite-contrast: #000000;
  --ion-color-cognicoconutwhite-contrast-rgb: 0,0,0;
  --ion-color-cognicoconutwhite-shade: #d5d5d5;
  --ion-color-cognicoconutwhite-tint: #f3f3f3;

  --ion-color-cognigreen: #759400;
  --ion-color-cognigreen-rgb: 117,148,0;
  --ion-color-cognigreen-contrast: #ffffff;
  --ion-color-cognigreen-contrast-rgb: 255,255,255;
  --ion-color-cognigreen-shade: #678200;
  --ion-color-cognigreen-tint: #839f1a;

  --ion-color-cogniolive: #9DB543;
  --ion-color-cogniolive-rgb: 157,181,67;
  --ion-color-cogniolive-contrast: #000000;
  --ion-color-cogniolive-contrast-rgb: 0,0,0;
  --ion-color-cogniolive-shade: #8a9f3b;
  --ion-color-cogniolive-tint: #a7bc56;

  --ion-color-cognipear: #BCD94E;
  --ion-color-cognipear-rgb: 188,217,78;
  --ion-color-cognipear-contrast: #000000;
  --ion-color-cognipear-contrast-rgb: 0,0,0;
  --ion-color-cognipear-shade: #a5bf45;
  --ion-color-cognipear-tint: #c3dd60;

  --ion-color-cognilime: #C8E753;
  --ion-color-cognilime-rgb: 200,231,83;
  --ion-color-cognilime-contrast: #000000;
  --ion-color-cognilime-contrast-rgb: 0,0,0;
  --ion-color-cognilime-shade: #b0cb49;
  --ion-color-cognilime-tint: #cee964;

  --ion-color-cogniorange: #FFAE52;
  --ion-color-cogniorange-rgb: 255,174,82;
  --ion-color-cogniorange-contrast: #ffffff;
  --ion-color-cogniorange-contrast-rgb: 255,255,255;
  --ion-color-cogniorange-shade: #e09948;
  --ion-color-cogniorange-tint: #ffb663;

  --ion-color-honey: #FFB038;
  --ion-color-honey-rgb: 255,176,56;
  --ion-color-honey-contrast: #000000;
  --ion-color-honey-contrast-rgb: 0,0,0;
  --ion-color-honey-shade: #e09b31;
  --ion-color-honey-tint: #ffb84c;

  --ion-color-cognimustard: #E7CE57;
  --ion-color-cognimustard-rgb: 231,206,87;
  --ion-color-cognimustard-contrast: #000000;
  --ion-color-cognimustard-contrast-rgb: 0,0,0;
  --ion-color-cognimustard-shade: #cbb54d;
  --ion-color-cognimustard-tint: #e9d368;  

  --ion-color-cogniwhite: #ffffff;
  --ion-color-cogniwhite-rgb: 255,255,255;
  --ion-color-cogniwhite-contrast: #000000;
  --ion-color-cogniwhite-contrast-rgb: 0,0,0;
  --ion-color-cogniwhite-shade: #e0e0e0;
  --ion-color-cogniwhite-tint: #ffffff;

  --ion-color-cogniblack: #000000;
  --ion-color-cogniblack-rgb: 0,0,0;
  --ion-color-cogniblack-contrast: #ffffff;
  --ion-color-cogniblack-contrast-rgb: 255,255,255;
  --ion-color-cogniblack-shade: #000000;
  --ion-color-cogniblack-tint: #1a1a1a;

  --ion-color-cognibubblegum: #D588A6;
  --ion-color-cognibubblegum-rgb: 213,136,166;
  --ion-color-cognibubblegum-contrast: #000000;
  --ion-color-cognibubblegum-contrast-rgb: 0,0,0;
  --ion-color-cognibubblegum-shade: #bb7892;
  --ion-color-cognibubblegum-tint: #d994af;
  
  --ion-color-cognimoodmostpositive: #E4CB4D;
  --ion-color-cognimoodmostpositive-rgb: 228,203,77;
  --ion-color-cognimoodmostpositive-contrast: #ffffff;
  --ion-color-cognimoodmostpositive-contrast-rgb: 255,255,255;
  --ion-color-cognimoodmostpositive-shade: #c9b344;
  --ion-color-cognimoodmostpositive-tint: #e7d05f;

  --ion-color-cognimoodpositive: #5C7F9D;
  --ion-color-cognimoodpositive-rgb: 92,127,157;
  --ion-color-cognimoodpositive-contrast: #ffffff;
  --ion-color-cognimoodpositive-contrast-rgb: 255,255,255;
  --ion-color-cognimoodpositive-shade: #51708a;
  --ion-color-cognimoodpositive-tint: #6c8ca7;

  --ion-color-cognimoodneutral: #6B9DB8;
  --ion-color-cognimoodneutral-rgb: 107,157,184;
  --ion-color-cognimoodneutral-contrast: #ffffff;
  --ion-color-cognimoodneutral-contrast-rgb: 255,255,255;
  --ion-color-cognimoodneutral-shade: #5e8aa2;
  --ion-color-cognimoodneutral-tint: #7aa7bf;

  --ion-color-cognimoodnegative: #9A8AB2;
  --ion-color-cognimoodnegative-rgb: 154,138,178;
  --ion-color-cognimoodnegative-contrast: #ffffff;
  --ion-color-cognimoodnegative-contrast-rgb: 255,255,255;
  --ion-color-cognimoodnegative-shade: #88799d;
  --ion-color-cognimoodnegative-tint: #a496ba;

  --ion-color-cognimoodmostnegative: #DF92B0;
  --ion-color-cognimoodmostnegative-rgb: 223,146,176;
  --ion-color-cognimoodmostnegative-contrast: #ffffff;
  --ion-color-cognimoodmostnegative-contrast-rgb: 255,255,255;
  --ion-color-cognimoodmostnegative-shade: #c4809b;
  --ion-color-cognimoodmostnegative-tint: #e29db8;
  
  --ion-color-cognipumpkin: #FE7E1D;
  --ion-color-cognipumpkin-rgb: 254,126,29;
  --ion-color-cognipumpkin-contrast: #FFFFFF;
  --ion-color-cognipumpkin-contrast-rgb: 0,0,0;
  --ion-color-cognipumpkin-shade: #e06f1a;
  --ion-color-cognipumpkin-tint: #fe8b34;

  --ion-color-cognisuperorange: #FC4A1A;
  --ion-color-cognisuperorange-rgb: 252,74,26;
  --ion-color-cognisuperorange-contrast: #ffffff;
  --ion-color-cognisuperorange-contrast-rgb: 255,255,255;
  --ion-color-cognisuperorange-shade: #de4117;
  --ion-color-cognisuperorange-tint: #fc5c31;
  
  --ion-color-cogniplaceholderok: rgba(0, 0, 0, 0.3);
  --ion-color-cogniplaceholdererror: rgba(176, 48, 47, 0.3);
  --ion-color-cogniiron: #5C5C5C;
  --ion-color-cognilavender: #F5D3C6;
  --ion-color-cognipigeon: #9DB6C8;
}

.ion-color-cogniblue {
  --ion-color-base: var(--ion-color-cogniblue);
  --ion-color-base-rgb: var(--ion-color-cogniblue-rgb);
  --ion-color-contrast: var(--ion-color-cogniblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniblue-shade);
  --ion-color-tint: var(--ion-color-cogniblue-tint);
}

.ion-color-cognibluesh {
  --ion-color-base: var(--ion-color-cognibluesh);
  --ion-color-base-rgb: var(--ion-color-cognibluesh-rgb);
  --ion-color-contrast: var(--ion-color-cognibluesh-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognibluesh-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognibluesh-shade);
  --ion-color-tint: var(--ion-color-cognibluesh-tint);
}

.ion-color-cognistone {
  --ion-color-base: var(--ion-color-cognistone);
  --ion-color-base-rgb: var(--ion-color-cognistone-rgb);
  --ion-color-contrast: var(--ion-color-cognistone-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognistone-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognistone-shade);
  --ion-color-tint: var(--ion-color-cognistone-tint);
}

.ion-color-cognisteel {
  --ion-color-base: var(--ion-color-cognisteel);
  --ion-color-base-rgb: var(--ion-color-cognisteel-rgb);
  --ion-color-contrast: var(--ion-color-cognisteel-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognisteel-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognisteel-shade);
  --ion-color-tint: var(--ion-color-cognisteel-tint);
}

.ion-color-cognispruce {
  --ion-color-base: var(--ion-color-cognispruce);
  --ion-color-base-rgb: var(--ion-color-cognispruce-rgb);
  --ion-color-contrast: var(--ion-color-cognispruce-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognispruce-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognispruce-shade);
  --ion-color-tint: var(--ion-color-cognispruce-tint);
}

.ion-color-cognicyprus {
  --ion-color-base: var(--ion-color-cognicyprus);
  --ion-color-base-rgb: var(--ion-color-cognicyprus-rgb);
  --ion-color-contrast: var(--ion-color-cognicyprus-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognicyprus-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognicyprus-shade);
  --ion-color-tint: var(--ion-color-cognicyprus-tint);
}

.ion-color-cognislate {
  --ion-color-base: var(--ion-color-cognislate);
  --ion-color-base-rgb: var(--ion-color-cognislate-rgb);
  --ion-color-contrast: var(--ion-color-cognislate-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognislate-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognislate-shade);
  --ion-color-tint: var(--ion-color-cognislate-tint);
}

.ion-color-cognisapphire {
  --ion-color-base: var(--ion-color-cognisapphire);
  --ion-color-base-rgb: var(--ion-color-cognisapphire-rgb);
  --ion-color-contrast: var(--ion-color-cognisapphire-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognisapphire-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognisapphire-shade);
  --ion-color-tint: var(--ion-color-cognisapphire-tint);
}

.ion-color-cognicharcoal {
  --ion-color-base: var(--ion-color-cognicharcoal);
  --ion-color-base-rgb: var(--ion-color-cognicharcoal-rgb);
  --ion-color-contrast: var(--ion-color-cognicharcoal-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognicharcoal-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognicharcoal-shade);
  --ion-color-tint: var(--ion-color-cognicharcoal-tint);
}

.ion-color-cognishadow {
  --ion-color-base: var(--ion-color-cognishadow);
  --ion-color-base-rgb: var(--ion-color-cognishadow-rgb);
  --ion-color-contrast: var(--ion-color-cognishadow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognishadow-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognishadow-shade);
  --ion-color-tint: var(--ion-color-cognishadow-tint);
}

.ion-color-cognismoke {
  --ion-color-base: var(--ion-color-cognismoke);
  --ion-color-base-rgb: var(--ion-color-cognismoke-rgb);
  --ion-color-contrast: var(--ion-color-cognismoke-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognismoke-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognismoke-shade);
  --ion-color-tint: var(--ion-color-cognismoke-tint);
}

.ion-color-cognigrey {
  --ion-color-base: var(--ion-color-cognigrey);
  --ion-color-base-rgb: var(--ion-color-cognigrey-rgb);
  --ion-color-contrast: var(--ion-color-cognigrey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognigrey-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognigrey-shade);
  --ion-color-tint: var(--ion-color-cognigrey-tint);
}

.ion-color-cognisilver {
  --ion-color-base: var(--ion-color-cognisilver);
  --ion-color-base-rgb: var(--ion-color-cognisilver-rgb);
  --ion-color-contrast: var(--ion-color-cognisilver-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognisilver-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognisilver-shade);
  --ion-color-tint: var(--ion-color-cognisilver-tint);
}

.ion-color-cogniskeleton {
  --ion-color-base: var(--ion-color-cogniskeleton);
  --ion-color-base-rgb: var(--ion-color-cogniskeleton-rgb);
  --ion-color-contrast: var(--ion-color-cogniskeleton-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniskeleton-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniskeleton-shade);
  --ion-color-tint: var(--ion-color-cogniskeleton-tint);
}

.ion-color-cognisnow {
  --ion-color-base: var(--ion-color-cognisnow);
  --ion-color-base-rgb: var(--ion-color-cognisnow-rgb);
  --ion-color-contrast: var(--ion-color-cognisnow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognisnow-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognisnow-shade);
  --ion-color-tint: var(--ion-color-cognisnow-tint);
}

.ion-color-cognifog {
  --ion-color-base: var(--ion-color-cognifog);
  --ion-color-base-rgb: var(--ion-color-cognifog-rgb);
  --ion-color-contrast: var(--ion-color-cognifog-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognifog-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognifog-shade);
  --ion-color-tint: var(--ion-color-cognifog-tint);
}

.ion-color-cognicloud {
  --ion-color-base: var(--ion-color-cognicloud);
  --ion-color-base-rgb: var(--ion-color-cognicloud-rgb);
  --ion-color-contrast: var(--ion-color-cognicloud-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognicloud-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognicloud-shade);
  --ion-color-tint: var(--ion-color-cognicloud-tint);
}

.ion-color-cognichalk {
  --ion-color-base: var(--ion-color-cognichalk);
  --ion-color-base-rgb: var(--ion-color-cognichalk-rgb);
  --ion-color-contrast: var(--ion-color-cognichalk-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognichalk-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognichalk-shade);
  --ion-color-tint: var(--ion-color-cognichalk-tint);
}

.ion-color-cognimilk {
  --ion-color-base: var(--ion-color-cognimilk);
  --ion-color-base-rgb: var(--ion-color-cognimilk-rgb);
  --ion-color-contrast: var(--ion-color-cognimilk-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimilk-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimilk-shade);
  --ion-color-tint: var(--ion-color-cognimilk-tint);
}

.ion-color-cognired {
  --ion-color-base: var(--ion-color-cognired);
  --ion-color-base-rgb: var(--ion-color-cognired-rgb);
  --ion-color-contrast: var(--ion-color-cognired-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognired-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognired-shade);
  --ion-color-tint: var(--ion-color-cognired-tint);
}

.ion-color-cognioldrose {
  --ion-color-base: var(--ion-color-cognioldrose);
  --ion-color-base-rgb: var(--ion-color-cognioldrose-rgb);
  --ion-color-contrast: var(--ion-color-cognioldrose-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognioldrose-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognioldrose-shade);
  --ion-color-tint: var(--ion-color-cognioldrose-tint);
}

.ion-color-cogniblush {
  --ion-color-base: var(--ion-color-cogniblush);
  --ion-color-base-rgb: var(--ion-color-cogniblush-rgb);
  --ion-color-contrast: var(--ion-color-cogniblush-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniblush-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniblush-shade);
  --ion-color-tint: var(--ion-color-cogniblush-tint);
}

.ion-color-cognicrepe {
  --ion-color-base: var(--ion-color-cognicrepe);
  --ion-color-base-rgb: var(--ion-color-cognicrepe-rgb);
  --ion-color-contrast: var(--ion-color-cognicrepe-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognicrepe-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognicrepe-shade);
  --ion-color-tint: var(--ion-color-cognicrepe-tint);
}

.ion-color-cognicoconutwhite {
  --ion-color-base: var(--ion-color-cognicoconutwhite);
  --ion-color-base-rgb: var(--ion-color-cognicoconutwhite-rgb);
  --ion-color-contrast: var(--ion-color-cognicoconutwhite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognicoconutwhite-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognicoconutwhite-shade);
  --ion-color-tint: var(--ion-color-cognicoconutwhite-tint);
}

.ion-color-cognigreen {
  --ion-color-base: var(--ion-color-cognigreen);
  --ion-color-base-rgb: var(--ion-color-cognigreen-rgb);
  --ion-color-contrast: var(--ion-color-cognigreen-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognigreen-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognigreen-shade);
  --ion-color-tint: var(--ion-color-cognigreen-tint);
}

.ion-color-cogniolive {
  --ion-color-base: var(--ion-color-cogniolive);
  --ion-color-base-rgb: var(--ion-color-cogniolive-rgb);
  --ion-color-contrast: var(--ion-color-cogniolive-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniolive-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniolive-shade);
  --ion-color-tint: var(--ion-color-cogniolive-tint);
}

.ion-color-cognipear {
  --ion-color-base: var(--ion-color-cognipear);
  --ion-color-base-rgb: var(--ion-color-cognipear-rgb);
  --ion-color-contrast: var(--ion-color-cognipear-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognipear-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognipear-shade);
  --ion-color-tint: var(--ion-color-cognipear-tint);
}

.ion-color-cognilime {
  --ion-color-base: var(--ion-color-cognilime);
  --ion-color-base-rgb: var(--ion-color-cognilime-rgb);
  --ion-color-contrast: var(--ion-color-cognilime-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognilime-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognilime-shade);
  --ion-color-tint: var(--ion-color-cognilime-tint);
}

.ion-color-cogniorange {
  --ion-color-base: var(--ion-color-cogniorange);
  --ion-color-base-rgb: var(--ion-color-cogniorange-rgb);
  --ion-color-contrast: var(--ion-color-cogniorange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniorange-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniorange-shade);
  --ion-color-tint: var(--ion-color-cogniorange-tint);
}

.ion-color-cognihoney {
  --ion-color-base: var(--ion-color-honey);
  --ion-color-base-rgb: var(--ion-color-honey-rgb);
  --ion-color-contrast: var(--ion-color-honey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-honey-contrast-rgb);
  --ion-color-shade: var(--ion-color-honey-shade);
  --ion-color-tint: var(--ion-color-honey-tint);
}

.ion-color-cognimustard {
  --ion-color-base: var(--ion-color-cognimustard);
  --ion-color-base-rgb: var(--ion-color-cognimustard-rgb);
  --ion-color-contrast: var(--ion-color-cognimustard-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimustard-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimustard-shade);
  --ion-color-tint: var(--ion-color-cognimustard-tint);
}

.ion-color-cogniwhite {
  --ion-color-base: var(--ion-color-cogniwhite);
  --ion-color-base-rgb: var(--ion-color-cogniwhite-rgb);
  --ion-color-contrast: var(--ion-color-cogniwhite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniwhite-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniwhite-shade);
  --ion-color-tint: var(--ion-color-cogniwhite-tint);
}

.ion-color-cogniblack {
  --ion-color-base: var(--ion-color-cogniblack);
  --ion-color-base-rgb: var(--ion-color-cogniblack-rgb);
  --ion-color-contrast: var(--ion-color-cogniblack-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cogniblack-contrast-rgb);
  --ion-color-shade: var(--ion-color-cogniblack-shade);
  --ion-color-tint: var(--ion-color-cogniblack-tint);
}

.ion-color-cognibubblegum {
  --ion-color-base: var(--ion-color-cognibubblegum);
  --ion-color-base-rgb: var(--ion-color-cognibubblegum-rgb);
  --ion-color-contrast: var(--ion-color-cognibubblegum-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognibubblegum-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognibubblegum-shade);
  --ion-color-tint: var(--ion-color-cognibubblegum-tint);
}

.ion-color-cognimoodmostpositive {
  --ion-color-base: var(--ion-color-cognimoodmostpositive);
  --ion-color-base-rgb: var(--ion-color-cognimoodmostpositive-rgb);
  --ion-color-contrast: var(--ion-color-cognimoodmostpositive-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimoodmostpositive-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimoodmostpositive-shade);
  --ion-color-tint: var(--ion-color-cognimoodmostpositive-tint);
}

.ion-color-cognimoodpositive {
  --ion-color-base: var(--ion-color-cognimoodpositive);
  --ion-color-base-rgb: var(--ion-color-cognimoodpositive-rgb);
  --ion-color-contrast: var(--ion-color-cognimoodpositive-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimoodpositive-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimoodpositive-shade);
  --ion-color-tint: var(--ion-color-cognimoodpositive-tint);
}

.ion-color-cognimoodneutral {
  --ion-color-base: var(--ion-color-cognimoodneutral);
  --ion-color-base-rgb: var(--ion-color-cognimoodneutral-rgb);
  --ion-color-contrast: var(--ion-color-cognimoodneutral-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimoodneutral-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimoodneutral-shade);
  --ion-color-tint: var(--ion-color-cognimoodneutral-tint);
}

.ion-color-cognimoodnegative {
  --ion-color-base: var(--ion-color-cognimoodnegative);
  --ion-color-base-rgb: var(--ion-color-cognimoodnegative-rgb);
  --ion-color-contrast: var(--ion-color-cognimoodnegative-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimoodnegative-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimoodnegative-shade);
  --ion-color-tint: var(--ion-color-cognimoodnegative-tint);
}

.ion-color-cognimoodmostnegative {
  --ion-color-base: var(--ion-color-cognimoodmostnegative);
  --ion-color-base-rgb: var(--ion-color-cognimoodmostnegative-rgb);
  --ion-color-contrast: var(--ion-color-cognimoodmostnegative-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognimoodmostnegative-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognimoodmostnegative-shade);
  --ion-color-tint: var(--ion-color-cognimoodmostnegative-tint);
}

.ion-color-cognipumpkin {
  --ion-color-base: var(--ion-color-cognipumpkin);
  --ion-color-base-rgb: var(--ion-color-cognipumpkin-rgb);
  --ion-color-contrast: var(--ion-color-cognipumpkin-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognipumpkin-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognipumpkin-shade);
  --ion-color-tint: var(--ion-color-cognipumpkin-tint);
}

.ion-color-cognisuperorange {
  --ion-color-base: var(--ion-color-cognisuperorange);
  --ion-color-base-rgb: var(--ion-color-cognisuperorange-rgb);
  --ion-color-contrast: var(--ion-color-cognisuperorange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cognisuperorange-contrast-rgb);
  --ion-color-shade: var(--ion-color-cognisuperorange-shade);
  --ion-color-tint: var(--ion-color-cognisuperorange-tint);
}