/* ITCSS Settings Files */
@import "./settings.colors.scss";
@import "./settings.grid.scss";
@import "./settings.typography.scss";

/* ITCSS Tools Files */

/* ITCSS Generic Files */

/* ITCSS Elements Files */
@import "./elements.html.scss";
@import "./elements.ionic.scss";

/* ITCSS Objects Files */

/* ITCSS Components Files */
@import "./components.buttons.scss";

/* ITCSS Utilities Files */
