:root {
  --ion-grid-columns: 12;
}

@media  (min-width: 280px) and (max-width: 991.98px) {
  .col-padding {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .col-padding {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media  (min-width: 1200px) {
  .col-padding {
    padding-left: 10px;
    padding-right: 10px;
  }
}