ion-grid {
  padding: 0px;
}

ion-col {
  padding: 0px;
}

ion-button {
  text-transform: none;
  --border-radius: 0;
  border-radius: 0;
  --box-shadow: 0 0 0 0;
  --border-width: 0;
}

ion-footer {
  position: absolute;
  bottom: var(--ion-safe-area-bottom, 0);
}

ion-loading.cogni-loading > div {
  --background: transparent;
  box-shadow: none !important;
} // scale // ion-loading.cogni-loading > div > div > ion-spinner {  transform: scale(3); }
