.btn-rounded-2 {
  --border-radius: 2px;
  border-radius: 2px;
}

.btn-rounded-5 {
  --border-radius: 5px;
  border-radius: 5px;
}

.btn-rounded-10 {
  --border-radius: 10px;
  border-radius: 10px;
}

.btn-rounded-15 {
  --border-radius: 15px;
  border-radius: 15px;
}
